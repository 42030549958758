import { ISetupForecastYearFormData } from 'interfaces/demographics';
import classes from './styles.module.scss';
import { memo, useEffect, useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/Input';

interface ModalSetupForecastYearProps {
  isOpen: boolean;
  forecastYear: number;
  onClose: () => void;
  onSubmit: (data: ISetupForecastYearFormData) => void;
}

const ModalSetupForecastYear = memo((props: ModalSetupForecastYearProps) => {
  const { isOpen, forecastYear, onClose, onSubmit } = props;

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      forecastYear: Yup.number()
        .required('Forecast year is required.')
        .typeError('Forecast year is required.')
        .moreThan(0, 'Invalid forecast year.')
        .max(9999, 'Invalid forecast year.'),
    });
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ISetupForecastYearFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (forecastYear) {
      reset({
        forecastYear,
      });
    }
  }, [forecastYear]);

  return (
    <Dialog transitionDuration={0.3} open={isOpen} onClose={onClose} className={classes.container}>
      <DialogTitle className={classes.title}>Setup forecast year for dataset</DialogTitle>

      <DialogContent>
        <form id="form-download-table" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Input
                label="Forecast year"
                placeholder="Forecast year"
                inputRef={register('forecastYear')}
                errorMessage={errors.forecastYear?.message}
                subLabel="The forecast year will affect the calculation formula of the model."
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions sx={{ mt: 2 }} className={classes.dialogAction}>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>

        <PrimaryButton type="submit" form="form-download-table">
          Upload dataset
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

export default ModalSetupForecastYear;
