import { ITableRowColor } from 'interfaces/common';

export const TRIAL_COUNTRY = 'New Zealand';

export const SEARCH_DELAY = 500; // 0.5 second

export const NULL_VALUE = 'null';

export const NULL_LABEL = 'None';

export const NULL_COLOR = '94a3b8';

export const EMPTY_OPTION = { value: 'EMPTY_OPTION_VALUE', label: 'Empty (Default)' };

export const ALL_OPTION = { value: 'ALL_OPTION_VALUE', label: 'All' };

export const DATE_FORMAT = 'DD MMM, YYYY';

export const MAX_IMAGE_SIZE = 5242880; // 5 MB

export const TABLE_ROW_COLOR: ITableRowColor[] = [
  {
    primary: 'var(--rowColorPrimary)',
    secondary: 'var(--rowColorSecondary)',
  },
  {
    primary: 'var(--rowColorPrimary1)',
    secondary: 'var(--rowColorSecondary1)',
  },
  {
    primary: 'var(--rowColorPrimary2)',
    secondary: 'var(--rowColorSecondary2)',
  },
  {
    primary: 'var(--rowColorPrimary3)',
    secondary: 'var(--rowColorSecondary3)',
  },
  {
    primary: 'var(--rowColorPrimary4)',
    secondary: 'var(--rowColorSecondary4)',
  },
];
