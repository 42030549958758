const Messages = {
  success: {
    default: 'Updated successfully.',
    created: 'Created successfully.',
    deleted: 'Deleted successfully.',
    removed: 'Removed successfully.',
    uploaded: 'Uploaded successfully.',
    verifyEmailSent: 'Email has been sent successfully, please check your email to verify your account.',
    verifyEmailSuccess: 'Email verified successfully.',
    resetPasswordEmailSent: 'Email has been sent successfully, please check your email to reset your password.',
    resetPasswordSuccess: 'Password has been reset successfully.',
    runNewScenario: 'New scenario with changed variables has been run successfully.',
  },
  error: {
    default: 'Sorry, something went wrong. Please contact our support team.',
    oneFileOnly: 'Please upload one file only.',
    requiredFile: 'Please upload file.',
    imageSize: 'Image size exceeds 5MB maximum limit.',
    imageType: 'Only images in JPEG, JPG, or PNG formats are accepted.',
    excelFile: 'Only files in XLSX format are accepted.',
    wrongEmail: "This email address doesn't exist.",
    wrongPassword: 'Your password is not correct.',
    existedEmail: 'This email address already exists.',
    loginByEmail: 'Account already exists, please login using your email.',
    wrongOldPassword: 'Old password is not correct.',
    verifyEmailInvalidLink: 'Your activation link is invalid or expired.',
    suspendedAccount: 'Your account has been suspended. Please contact our support team.',
    emailVerifiedOrNotFound: 'Email not found or already verified.',
    waitForSendEmail: 'Verification email is already sent, please wait 60 seconds to resend.',
    resetPasswordInvalidLink: 'Your reset password link is invalid or expired.',
  },
};

export default Messages;
