import { Box, Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { DefaultLogo } from 'assets';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { Fragment, memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { appRoutes } from 'routers/appRoutes';
import classes from './styles.module.scss';
import { useDispatch } from 'react-redux';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiService from 'services/apiService';
import apiRoutes from 'configs/apiRoutes';
import { IUser } from 'interfaces/user';
import { setUserReducer } from 'redux/reducers/User/actionTypes';
import { push } from 'connected-react-router';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import categoryPricingPlans from 'configs/plansNew';
import { ICategoryPricingPlan, IPricingPlan } from 'interfaces/plan';
interface ConditionPageProps {

}

const ConditionPage: React.FC<ConditionPageProps> = memo((props: ConditionPageProps) => {
    const dispatch = useDispatch();

    const [accepted, setAccepted] = useState<boolean>(false );

    const submitAccept = async (type: string) => {
        dispatch(setIsLoadingReducer(true));
        await ApiService.PUT(apiRoutes.user.acceptCondition, {
            isAcceptCondition: true
        })
            .then((response) => { 
                dispatch(setIsLoadingReducer(false));
                const user: IUser = response?.data?.user;
                dispatch(setUserReducer(user));
                if(type === "Continue"){
                    dispatch(push(appRoutes.private.home));
                }else{
                    dispatch(push(appRoutes.private.pricing));
                }
            })
            .catch(() => {
                dispatch(setIsLoadingReducer(false));
            })
    }

    return (
        <Fragment>
            <Link to={appRoutes.private.home} className={classes.logo}>
                <img className={classes.logoImage} src={DefaultLogo} alt="Logo" />
            </Link>
            <Box display="flex" className={classes.groupTitle}>
                <p className={classes.title}>Terms and Conditions</p>
                <p className={classes.title}>Pricing</p>
            </Box>
            <Grid  container justifyContent="center" spacing={6}>
                <Grid className={classes.itemContentCondition} item xs={12} sm={6}>
                    <Box className="contentCondition">
                        <p className={classes.title}>Terms and Conditions</p>
                        <Box className={classes.disCondition}>
                            <p>This Model was produced by Global Demographics Ltd as of the date of delivery and is subject to change.Global Demographics Ltd reserves the right to make changes to the information in this model at any time without further notice.</p>
                            <p>The information contained herein is given in good faith but without guarantee and Global Demographics Ltd  do not make any representation as to its accuracy or completeness and does not accept liability for any loss and/or damage arising from the use herein.</p>
                            <p>Neither this model nor any of its contents may be used, copied, reproduced, disseminated, quoted or referred to in any presentation, agreement or document (with or without attribution to Global Demographics Ltd) at any time or in any manner without the express permission of Global Demographics Ltd.</p>
                            <p>Pricing  (same for Cuntry and China Packages).</p>
                            <p>By click on this button you accept the conditions of use stated above.</p>
                        </Box>
                    </Box>
                </Grid>
                <Grid className={classes.itemContentCondition} item xs={12} sm={6}>
                    <p className={classes.title}>Pricing</p>
                    <Box className={classes.disCondition}>
                        <table className={classes.tablePackage} >
                            {categoryPricingPlans?.map((category: ICategoryPricingPlan, categoryIndex: number) => (
                                <tbody key={categoryIndex}>
                                    <tr>
                                        <td colSpan={2} className={classes.tdTitle}>{category?.title}</td>
                                        <td rowSpan={5} className={classes.tdSubtitle}><div className={classes.subtitle}>{category?.subtitle}</div></td>
                                    </tr>
                                    {category?.listPlan?.map((plan: IPricingPlan, planIndex: number) => (
                                        <tr key={planIndex}>
                                            <td >{plan?.title}</td>
                                            <td >{`$${plan?.price}`}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan={3}></td>
                                    </tr>
                                </tbody>
                            ))}
                        </table>
                    </Box>
                </Grid>
                <Grid item md={12} >
                    <Box justifyContent="center" display="flex" >
                        <FormGroup>
                            <FormControlLabel 
                            control={<Checkbox onChange={(event=>{
                                setAccepted(!!event.target.checked)
                            })}/>} 
                            label={
                                <div>
                                    <span style={{ fontWeight: 'bold' }}>I have read and agreed</span> 
                                    <span> to the terms and conditions.</span>
                                </div>
                            } />
                        </FormGroup>
                    </Box>
                    <Box className={classes.groupBtn}>
                        <SecondaryButton disabled={!accepted} type="submit" fullWidth onClick={() => {submitAccept("Continue")}}>
                            Continue to Free Trial
                        </SecondaryButton>
                        <PrimaryButton disabled={!accepted} type="submit" fullWidth onClick={() => {submitAccept("Subcribe")}}>
                            Buy a package
                        </PrimaryButton>
                    </Box>
                </Grid>
            </Grid>
            
        </Fragment>
    )
})
export default ConditionPage