import { memo, useEffect } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { appRoutes } from 'routers/appRoutes';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiService from 'services/apiService';
import apiRoutes from 'configs/apiRoutes';
import ToastService from 'services/toastService';
import AuthService from 'services/authService';
import Messages from 'configs/messages';
import { setUserReducer } from 'redux/reducers/User/actionTypes';

interface Params {
  verifyCode: string;
}

interface VerifyEmailPageProps {}

const VerifyEmailPage: React.FC<VerifyEmailPageProps> = memo((props: VerifyEmailPageProps) => {
  const dispatch = useDispatch();

  const { verifyCode } = useParams<Params>();

  useEffect(() => {
    if (!verifyCode) {
      dispatch(push(appRoutes.public.login));
      return;
    }
    const activeUser = async () => {
      dispatch(setIsLoadingReducer(true));
      await ApiService.POST(apiRoutes.user.verifyEmail, {
        code: verifyCode,
      })
        .then((response) => {
          AuthService.login(response?.data);
          dispatch(setUserReducer(response?.data?.user));
          dispatch(push(appRoutes.private.home));
          ToastService.success(Messages.success.verifyEmailSuccess);
        })
        .catch(() => {
          ToastService.error(Messages.error.verifyEmailInvalidLink);
          dispatch(push(appRoutes.public.login));
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    };
    activeUser();
  }, [verifyCode]);

  return null;
});

export default VerifyEmailPage;
