import { IChangeDemographicsFormData, IChangeDemographicsParams } from 'interfaces/demographics';
import classes from './styles.module.scss';
import { memo, useEffect, useMemo, useState } from 'react';
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Switch } from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useSelector } from 'react-redux';
import { IReducer } from 'redux/reducers';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'components/Select';
import { ETopic } from 'configs/enums';
import Input from 'components/Input';
import { CloseIcon, PlusBlackIcon } from 'assets';
import CommonService from 'services/commonService';
import CustomDatePicker from 'components/DatePicker';

interface ModalDownloadDataTableProps {
  isOpen: boolean;
  data: IChangeDemographicsParams;
  onClose: () => void;
  onSubmit: (data: IChangeDemographicsFormData) => void;
}

const ModalDownloadDataTable = memo((props: ModalDownloadDataTableProps) => {
  const { isOpen, data, onClose, onSubmit } = props;

  const { dataControl } = useSelector((state: IReducer) => state.dataControl);

  const [showCustomBreakpoints, setShowCustomBreakpoints] = useState<boolean>(false);

  const validationSchema = useMemo(() => {
    return Yup.object().shape(
      {
        topic: Yup.array().of(Yup.object()).min(1, 'Select at least one topic to show.').required('Select at least one topic to show.'),
        subTable: Yup.array().of(Yup.object()).min(1, 'Select at least one sub table to show.').required('Select at least one sub table to show.'),
        startYear: Yup.date()
          .required('Start year is required.')
          .typeError('Start year is required.')
          .when('endYear', (endYear, schema) => {
            if (endYear && dayjs(endYear).isValid()) {
              return schema.max(endYear, 'Start year must be before end year.').typeError('Start year is required.');
            }
          }),
        endYear: Yup.date()
          .required('End year is required.')
          .typeError('End year is required.')
          .when('startYear', (startYear, schema) => {
            if (startYear && dayjs(startYear).isValid()) {
              return schema.min(startYear, 'End year must be after start year.').typeError('End year is required.');
            }
          }),
        customBreakpoints: Yup.bool(),
        breakpoints: Yup.array().when('customBreakpoints', {
          is: true,
          then: Yup.array(
            Yup.object().shape({
              value: Yup.number()
                .required('Breakpoint is required.')
                .typeError('Breakpoint is required.')
                .moreThan(0, 'Income must be more than 0')
                .max(9999999999, 'Income too big'),
            })
          )
            .test('is-increasing', 'Each breakpoint value must be greater than the previous one', function (breakpoints) {
              if (breakpoints) {
                for (let i = 1; i < breakpoints.length; i++) {
                  if (breakpoints[i].value <= breakpoints[i - 1].value) {
                    return (this as Yup.TestContext)?.createError({
                      path: `breakpoints.${i}.value`,
                      message: `Each breakpoint value must be greater than the previous one.`,
                    });
                  }
                }
              }
              return true;
            })
            .required('Breakpoint is required.')
            .min(1, 'Breakpoint is required.'),
        }),
      },
      [['startYear', 'endYear']]
    );
  }, []);

  const modalTitle = useMemo(() => {
    const countries = dataControl?.regions
      ?.flatMap((region) => region?.countries)
      ?.filter((country) => data?.country?.includes(country.id))
      ?.map((country) => country.name)
      ?.join(', ');
    return countries?.length > 0 ? `Download demographics data of ${countries}` : '';
  }, [data, dataControl]);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    trigger,
    control,
  } = useForm<IChangeDemographicsFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      customBreakpoints: false,
      breakpoints: [
        {
          value: undefined,
        },
      ],
    },
  });

  const {
    fields: fieldsBreakpoints,
    append: appendBreakpoint,
    remove: removeBreakpoint,
  } = useFieldArray({
    control,
    name: 'breakpoints',
  });

  const watchStartYear = watch('startYear');
  const watchEndYear = watch('endYear');
  const watchCountry = watch('country');
  const watchTopic = watch('topic');

  useEffect(() => {
    reset({
      country: dataControl?.regions
        ?.flatMap((region) => region.countries)
        ?.filter((country) => data?.country?.includes(country.id))
        ?.map((country) => ({ value: country.id, label: country.name })),
      topic: dataControl?.topics?.filter((topic) => data?.topic?.includes(topic.id))?.map((topic) => ({ value: topic.id, label: topic.name })),
      subTable: dataControl?.subTables
        ?.filter((subTable) => data?.subTable?.includes(subTable.id))
        ?.map((subTable) => ({ value: subTable.id, label: subTable.name })),
      startYear: dayjs(`${data?.startYear}`),
      endYear: dayjs(`${data?.endYear}`),
      stepSize: 1,
      baseYear: dayjs(`${data?.startYear}`),
      ...(data?.breakpoint &&
        data?.breakpoint?.length > 0 && {
          customBreakpoints: true,
          breakpoints: data?.breakpoint?.map((breakpoint) => ({ value: breakpoint })),
        }),
      changedVariable: data?.changedVariable ?? [],
    });
  }, [data, dataControl]);

  useEffect(() => {
    if (watchTopic?.some((option) => option.value === ETopic.DistributionHouseholds || option.value === ETopic.HouseholdExp)) {
      setShowCustomBreakpoints(true);
    } else {
      setShowCustomBreakpoints(false);
    }
  }, [watchCountry, watchTopic]);

  useEffect(() => {
    if (watchStartYear && watchEndYear) {
      trigger(['startYear', 'endYear']);
    }
  }, [watchStartYear, watchEndYear]);

  const onAddBreakpoint = () => {
    appendBreakpoint({
      value: undefined,
    });
  };

  const onDeleteBreakpoint = (index: number) => {
    if (fieldsBreakpoints?.length <= 1) return;
    removeBreakpoint(index);
  };

  return (
    <Dialog
      PaperProps={{ style: { overflowY: 'visible' } }}
      maxWidth={'lg'}
      transitionDuration={0.3}
      open={isOpen}
      onClose={onClose}
      className={classes.container}
    >
      <DialogTitle className={classes.title}>{modalTitle}</DialogTitle>

      <DialogContent sx={{ overflowY: { md: 'visible' } }}>
        <form id="form-download-table" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Select
                label="Topic"
                placeholder="Select topic set to display"
                isMulti
                options={[
                  {
                    label: '',
                    options: dataControl?.topics?.map((topic) => ({ value: topic.id, label: topic.name })) ?? [],
                  },
                ]}
                name="topic"
                control={control}
                errorMessage={errors?.topic?.message as string}
                menuPortalTarget={document?.querySelector('.MuiDialog-root')}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Select
                label="Sub table"
                placeholder="Select sub tables to show"
                isMulti
                options={[
                  {
                    label: '',
                    options: dataControl?.subTables?.map((subTable) => ({ value: subTable.id, label: subTable.name })) ?? [],
                  },
                ]}
                name="subTable"
                control={control}
                errorMessage={errors?.subTable?.message as string}
                menuPortalTarget={document?.querySelector('.MuiDialog-root')}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <CustomDatePicker
                label="Start year"
                views={['year']}
                control={control}
                name="startYear"
                errorMessage={errors?.startYear?.message as string}
                datePickerProps={{
                  minDate: dayjs(`${dataControl?.startYear ?? 2005}`),
                  maxDate: dayjs(`${dataControl?.endYear ?? 2065}`),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <CustomDatePicker
                label="End year"
                views={['year']}
                control={control}
                name="endYear"
                errorMessage={errors?.endYear?.message as string}
                datePickerProps={{
                  minDate: dayjs(`${dataControl?.startYear ?? 2005}`),
                  maxDate: dayjs(`${dataControl?.endYear ?? 2065}`),
                }}
              />
            </Grid>

            {showCustomBreakpoints ? (
              <Grid item xs={12}>
                <div className={classes.breakpointWrapper}>
                  <Stack alignItems="center" direction="row">
                    <label>Household Income Breaks US$ pa:</label>

                    <Controller
                      name="customBreakpoints"
                      control={control}
                      render={({ field: { onChange, value } }) => <Switch className={classes.switch} checked={value ?? false} onChange={onChange} />}
                    />
                  </Stack>

                  {watch('customBreakpoints') === true ? (
                    <div className={classes.breakpointList}>
                      <Stack sx={{ flexDirection: { xs: 'column', md: 'row' } }} alignItems="start" flexWrap="wrap" rowGap={2} columnGap={3}>
                        {fieldsBreakpoints.map((field, index) => (
                          <div className={classes.breakpointItem} key={field.id}>
                            <span>
                              <strong>
                                {index <= 0
                                  ? `$0`
                                  : watch('breakpoints')?.[index - 1]?.value && !errors?.breakpoints?.[index - 1]?.value
                                  ? `$${CommonService.formatNumber(watch('breakpoints')?.[index - 1]?.value, 2)}`
                                  : 'N/A'}
                              </strong>{' '}
                              to{' '}
                            </span>

                            <Input
                              className={classes.breakpointInput}
                              type="number"
                              step="any"
                              inputRef={register(`breakpoints.${index}.value`)}
                              errorMessage={errors.breakpoints?.[index]?.value?.message}
                            />

                            {index > 0 ? (
                              <div className={classes.breakpointButton}>
                                <button onClick={() => onDeleteBreakpoint(index)} type="button">
                                  <CloseIcon />
                                </button>
                              </div>
                            ) : null}
                          </div>
                        ))}
                        <div className={classes.breakpointItem}>
                          <span>
                            Over{' '}
                            <strong>
                              {watch('breakpoints')?.[fieldsBreakpoints?.length - 1]?.value && !errors?.breakpoints?.[fieldsBreakpoints?.length - 1]?.value
                                ? `$${CommonService.formatNumber(watch('breakpoints')?.[fieldsBreakpoints?.length - 1]?.value, 2)}`
                                : 'N/A'}
                            </strong>
                          </span>

                          <div className={classes.breakpointButton}>
                            <button onClick={onAddBreakpoint} type="button">
                              <PlusBlackIcon />
                            </button>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  ) : null}
                </div>
              </Grid>
            ) : null}
          </Grid>
        </form>
      </DialogContent>

      <DialogActions sx={{ mt: 2 }} className={classes.dialogAction}>
        {data?.changedVariable?.length > 0 ? (
          <Alert sx={{ width: 'fit-content' }} severity="info">
            Changes have been applied
          </Alert>
        ) : null}

        <Stack direction={'row'} flexWrap={'wrap'} gap={2} sx={{ ml: 'auto' }}>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>

          <PrimaryButton type="submit" form="form-download-table">
            Download
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
});

export default ModalDownloadDataTable;
