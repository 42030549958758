import { memo } from 'react';
import classes from './styles.module.scss';
import { IPricingPlan } from 'interfaces/plan';
import { Box } from '@mui/material';
import clsx from 'clsx';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { EPlan } from 'configs/enums';
import { IUser } from 'interfaces/user';
import StripeService from 'services/stripeService';
interface PlanDetailsProps {
  data: IPricingPlan;
  user: IUser;
  onClick: (plan: EPlan) => void;
}

const PlanDetails = memo((props: PlanDetailsProps) => {
  const { data, user, onClick } = props;

  const getTitleButton = () => {
    if (!user?.subscription) return "Subscribe";

    const { planId, scheduledPlanId } = user.subscription;
    const PlanDetail = StripeService.getPlanDetail(planId);
    const isCurrentPlan = planId === data?.id;
    const isScheduledPlan = scheduledPlanId === data?.id;
    const planPrice = +PlanDetail.price;
    const dataPrice = +data?.price;

    if (isScheduledPlan) return "Scheduled plan";
    if (isCurrentPlan) return "Current Plan";
    if (planPrice === dataPrice) return "Change";
    return planPrice > dataPrice ? "Downgrade" : "Upgrade";
  }

  return (
    <div className={clsx(classes.planWrapper)}>
      <Box sx={{ mb: 2 }} display="flex" justifyContent="center">
        <img width={100} src={`${data?.imageSource}`} height={100} alt={`${data?.title.toLowerCase().replace(' ', '-')}-plan-img`} />
      </Box>

      <Box sx={{ textAlign: 'center' }}>
        <p className={classes.planTitle}> {data?.title ?? 'N/A'}</p>

        <p className={classes.planSubtitle}> {data?.subtitle ?? 'N/A'}</p>

        <Box sx={{ my: 2, position: 'relative' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <p className={classes.dollar}>$</p>
            <p className={classes.price}>{data?.price ?? 0}</p>
            <p className={classes.duration}>/ {data?.durationPlaceholder ?? 'N/A'}</p>
          </Box>
        </Box>
      </Box>

      <PrimaryButton
        className={classes.submitButton}
        onClick={() => onClick(data.id)}
        disabled={user?.subscription?.planId === data?.id || user?.subscription?.scheduledPlanId === data?.id}
      >
        {getTitleButton()}
      </PrimaryButton>
    </div>
  );
});

export default PlanDetails;
