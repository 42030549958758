import { Fragment, memo, useMemo } from 'react';
import classes from './styles.module.scss';
import Input from 'components/Input';
import * as Yup from 'yup';
import Regexes from 'configs/regexes';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { appRoutes } from 'routers/appRoutes';
import { Link } from 'react-router-dom';
import { DefaultLogo } from 'assets';
import ApiService from 'services/apiService';
import apiRoutes from 'configs/apiRoutes';
import ToastService from 'services/toastService';
import Messages from 'configs/messages';
import { useDispatch } from 'react-redux';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import { EStatusCode } from 'configs/enums';
import { push } from 'connected-react-router';

interface IForgotPasswordFromData {
  email: string;
}

interface ForgotPasswordPageProps {}

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = memo((props: ForgotPasswordPageProps) => {
  const dispatch = useDispatch();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().required('Please enter your email.').matches(Regexes.email, 'Please enter a valid email.'),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPasswordFromData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmitEmail = async (data: IForgotPasswordFromData) => {
    dispatch(setIsLoadingReducer(true));

    await ApiService.POST(apiRoutes.user.sendForgotPasswordEmail, data)
      .then(() => {
        dispatch(
          push({
            pathname: appRoutes.fullyAllowed.checkForgotPasswordEmail,
            search: `&email=${encodeURIComponent(data?.email)}`,
          })
        );
        ToastService.success(Messages.success.resetPasswordEmailSent);
      })
      .catch((error) => {
        console.log(error);
        switch (error?.response?.status) {
          case EStatusCode.NotFound:
            return ToastService.error(Messages.error.wrongEmail);
          default:
            return ToastService.error(Messages.error.default);
        }
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  return (
    <Fragment>
      <Link to={appRoutes.private.home}>
        <img className={classes.logoImage} src={DefaultLogo} alt="Logo" />
      </Link>

      <p className={classes.title}>Forgot Password</p>
      <form onSubmit={handleSubmit(onSubmitEmail)}>
        <p className="mb-4 text-center">You will receive an email with instructions on how to reset your password in a few minutes.</p>

        <Input
          label="Email"
          inputRef={register('email')}
          type="text"
          placeholder="Your email"
          autoComplete="email"
          errorMessage={errors?.email?.message}
        />

        <PrimaryButton className="mb-4" type="submit" fullWidth>
          Reset password
        </PrimaryButton>

        <div className="text-center">
          <Link to={appRoutes.public.login}>Login</Link>
        </div>
      </form>
    </Fragment>
  );
});

export default ForgotPasswordPage;
