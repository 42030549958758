import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { Card, CardContent, Grid, Stack } from '@mui/material';
import { UploadIcon } from 'assets';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { DataGrid, GridColDef, GridFilterModel, GridPaginationModel, GridSortModel, GridToolbar } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import ToastService from 'services/toastService';
import Messages from 'configs/messages';
import ApiService from 'services/apiService';
import apiRoutes from 'configs/apiRoutes';
import { useDispatch } from 'react-redux';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import { IConfirmModal, IDataPagination, ISortItem } from 'interfaces/common';
import { IDatasetHistory, IGetDatasetHistoriesParams, ISetupForecastYearFormData } from 'interfaces/demographics';
import { saveAs } from 'file-saver';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { getDataControlRequest } from 'redux/reducers/DataControl/actionTypes';
import ModalSetupForecastYear from './components/ModalSetupForecastYear';

const FILE_FORMATS = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

interface AdminImportDatasetPageProps {}

interface CellType {
  row: IDatasetHistory;
}

const AdminImportDatasetPage: React.FC<AdminImportDatasetPageProps> = memo((props: AdminImportDatasetPageProps) => {
  const dispatch = useDispatch();

  const imageInputRef = useRef<HTMLInputElement>();

  const [file, setFile] = useState<File>(null);
  const [keyword, setKeyword] = useState<string>(null);
  const [sort, setSort] = useState<ISortItem>();
  const [datasetHistories, setDatasetHistories] = useState<IDataPagination<IDatasetHistory>>();
  const [loadingDatasetHistory, setLoadingDatasetHistory] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<IConfirmModal>(null);
  const [openModalSetupForecastYear, setOpenModalSetupForecastYear] = useState<boolean>(false);
  const [forecastYear, setForecastYear] = useState<number>(null);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        flex: 0.1,
        field: 'id',
        headerName: '#',
      },
      {
        flex: 0.3,
        minWidth: 150,
        field: 'filename',
        headerName: 'File name',
      },
      {
        flex: 0.2,
        minWidth: 150,
        field: 'createdAt',
        headerName: 'Uploaded at',
        renderCell: ({ row }: CellType) => <p>{dayjs(row?.createdAt).format('DD-MM-YYYY')}</p>,
      },
      {
        flex: 0.2,
        minWidth: 220,
        field: '',
        sortable: false,
        headerName: 'Action',
        renderCell: ({ row }: CellType) => {
          return (
            <Stack direction="row" gap={2} alignItems="center" sx={{ height: '100%' }}>
              <div className={classes.actionButton}>
                <PrimaryButton onClick={() => onDownloadDataset(row)}>Download</PrimaryButton>
              </div>
              <div className={classes.actionButton}>
                <SecondaryButton
                  onClick={() => {
                    setConfirmModal({
                      isOpen: true,
                      title: 'Delete dataset history',
                      description:
                        'Are you sure you want to delete this history? The dataset file will also be deleted, but the imported dataset remains intact',
                      onSubmit: () => onDeleteDataset(row),
                    });
                  }}
                >
                  Delete
                </SecondaryButton>
              </div>
            </Stack>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    fetchData();
    fetchCurrentForecastYear();
  }, []);

  useEffect(() => {
    if (file) {
      setOpenModalSetupForecastYear(true);
    } else {
      imageInputRef.current.value = '';
    }
  }, [file]);

  const fetchCurrentForecastYear = useCallback(() => {
    dispatch(setIsLoadingReducer(true));
    ApiService.GET(apiRoutes.setup.forecastYear)
      .then((res) => {
        if (res?.data?.meta_value) {
          setForecastYear(parseInt(res?.data?.meta_value) ?? 2023);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  }, []);

  const onSelectFile = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const excelFile: File = event?.target?.files?.[0];
    if (excelFile) {
      if (!FILE_FORMATS.includes(excelFile.type)) {
        ToastService.error(Messages.error.excelFile);
      } else {
        setFile(excelFile);
      }
    }
    imageInputRef.current.value = '';
  }, []);

  const onDownloadDataset = useCallback((datasetHistory: IDatasetHistory) => {
    saveAs(datasetHistory.path, datasetHistory.filename);
  }, []);

  const onDeleteDataset = useCallback((datasetHistory: IDatasetHistory) => {
    setLoadingDatasetHistory(true);
    ApiService.DELETE(`${apiRoutes.datasetHistory.default}/${datasetHistory.id}`)
      .then(() => {
        fetchData();
        onCloseConfirmModal();
        ToastService.success(Messages.success.deleted);
      })
      .catch(() => {
        setLoadingDatasetHistory(false);
        ToastService.error(Messages.error.default);
      });
  }, []);

  const fetchData = useCallback(
    (value?: { limit?: number; page?: number; keyword?: string; sort?: ISortItem }) => {
      const params: IGetDatasetHistoriesParams = {
        limit: value?.limit || datasetHistories?.meta?.limit || 10,
        page: value?.page || datasetHistories?.meta?.page || 1,
        keyword: keyword,
        sortedField: sort?.sortedField,
        isDescending: sort?.isDescending,
      };
      if (value?.sort !== undefined) {
        params.sortedField = value?.sort?.sortedField;
        params.isDescending = value?.sort?.isDescending;
      }
      if (value?.keyword !== undefined) {
        params.keyword = value?.keyword || undefined;
      }

      setLoadingDatasetHistory(true);
      ApiService.GET(apiRoutes.datasetHistory.default, { ...params })
        .then((res) => {
          setDatasetHistories({
            data: res?.data,
            meta: res?.meta,
          });
        })
        .catch(() => ToastService.error(Messages.error.default))
        .finally(() => setLoadingDatasetHistory(false));
    },
    [datasetHistories, keyword, sort]
  );

  const onPaginationChange = useCallback(
    (val: GridPaginationModel) => {
      fetchData({
        page: val.page + 1,
        limit: val.pageSize,
      });
    },
    [fetchData]
  );

  const onSortChange = useCallback(
    (val: GridSortModel) => {
      const sortQuery: ISortItem =
        val?.length > 0 ? { sortedField: val?.[0]?.field ?? 'null', isDescending: val?.[0]?.sort === 'asc' ? false : true } : null;
      setSort(sortQuery);
      fetchData({
        sort: sortQuery,
      });
    },
    [fetchData]
  );

  const onFilterChange = useCallback(
    (val: GridFilterModel) => {
      setKeyword(val?.quickFilterValues?.[0] ?? '');
      fetchData({
        keyword: val?.quickFilterValues?.[0] ?? '',
      });
    },
    [fetchData]
  );

  const onUploadDataset = (data: ISetupForecastYearFormData) => {
    if (!file) {
      return ToastService.error(Messages.error.requiredFile);
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('forecastYear', `${data.forecastYear}`);

    dispatch(setIsLoadingReducer(true));
    ApiService.POST(apiRoutes.dataset.upload, formData)
      .then(() => {
        setFile(null);
        setOpenModalSetupForecastYear(false);
        fetchData();
        dispatch(getDataControlRequest());
        ToastService.success(Messages.success.uploaded);
      })
      .catch(() => ToastService.error(Messages.error.default))
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onCloseConfirmModal = () => {
    setConfirmModal(null);
  };

  const onCloseSetupForecastYearModal = () => {
    setOpenModalSetupForecastYear(false);
    setFile(null);
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ borderRadius: '10px' }}>
            <p className={classes.title}>Current forecast year: {forecastYear ?? ''}</p>

            <CardContent className={classes.content}>
              <div>
                <div className={classes.uploadWrapper}>
                  <div className={classes.uploadBox} onClick={() => imageInputRef?.current?.click()}>
                    <input ref={imageInputRef} type="file" onChange={onSelectFile} accept={FILE_FORMATS.join(', ')} />
                    <UploadIcon />
                    <p>Upload file</p>
                  </div>
                </div>

                <p className={classes.fileAllowed}>Allowed .xlsx</p>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={6} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Card sx={{ borderRadius: '10px' }}>
            <p className={classes.title}>Dataset history log</p>

            <CardContent className={classes.content}>
              <DataGrid<IDatasetHistory>
                autoHeight
                pagination
                columns={columns}
                rows={datasetHistories?.data ?? []}
                rowCount={datasetHistories?.meta?.itemCount || 0}
                disableRowSelectionOnClick
                disableColumnSelector
                paginationMode="server"
                filterMode="server"
                sortingMode="server"
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                disableColumnFilter
                onPaginationModelChange={onPaginationChange}
                onFilterModelChange={onFilterChange}
                onSortModelChange={onSortChange}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                loading={loadingDatasetHistory}
                sx={{
                  '*': {
                    outline: 'none !important',
                  },
                  '.MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 600,
                  },
                  '.MuiCircularProgress-svg': {
                    color: 'var(--primary)',
                  },
                  '.MuiButtonBase-root': {
                    color: 'var(--black)',
                  },
                  '.MuiInputBase-root::after': {
                    borderColor: 'var(--black)',
                  },
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <ConfirmModal
        isOpen={confirmModal?.isOpen}
        title={confirmModal?.title}
        description={confirmModal?.description}
        onSubmit={confirmModal?.onSubmit}
        onClose={onCloseConfirmModal}
      />

      {openModalSetupForecastYear ? (
        <ModalSetupForecastYear
          onSubmit={onUploadDataset}
          forecastYear={forecastYear}
          isOpen={openModalSetupForecastYear}
          onClose={onCloseSetupForecastYearModal}
        />
      ) : null}
    </div>
  );
});

export default AdminImportDatasetPage;
