import { memo } from 'react';
import classes from './styles.module.scss';

interface NonAuthorizedLayoutProps {
  children: React.ReactNode;
}

const NonAcceptedLayout: React.FC<NonAuthorizedLayoutProps> = memo((props: NonAuthorizedLayoutProps) => {
  const { children } = props;

  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.formWrapper}>
          <div className={classes.formContent}>{children}</div>
        </div>
      </div>
    </div>
  );
});

export default NonAcceptedLayout;
