export enum ENodeEnv {
  Development = "development",
  Production = "production",
}

export enum EUserType {
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
  Demo = 'DEMO',
  Custom = 'CUSTOM'
}

export enum EAccountStatus {
  Active = 1,
  Inactive = 0,
}

export enum EStatus {
  Active = 1,
  Suspend = 0,
}

export enum EToastStatus {
  Info,
  Error,
  Success,
  Warning,
}

export enum EStatusCode {
  MovedPermanently = 301,
  BadRequest = 400,
  Unauthorized = 401,
  AccessDenied = 403,
  NotFound = 404,
  InternalServerError = 500,
  BadGateway = 502,
}

export enum EVerifyCodeType {
  VerifyEmail = 1,
  ResetPassword = 2,
}

export enum ESubTable {
  BaseCase = 1,
  Revised = 2,
  Impact = 3,
}

export enum ETopic {
  Population = 1,
  LabourForce,
  ProductivityAndGDP,
  HouseholdIncome,
  AgeProfile,
  DistributionHouseholds,
  HouseholdExp,
}

export enum EPlan {
  DailyPremium = 1,
  WeeklyPremium = 2,
  MonthlyPremium = 3,
  YearlyPremium = 4,
  DailyPro = 5,
  WeeklyPro = 6,
  MonthlyPro = 7,
  YearlyPro = 8,
  DailyBasic = 9,
  WeeklyBasic = 10,
  MonthlyBasic = 11,
  YearlyBasic = 12,
}
export enum CategoryPlan{
  All = 1,
  AllExcludingChina = 2,
  China = 3
}
export enum EIsRecurring {
  False = 0,
  True = 1,
}

export enum EBillingStatus {
  Draft = 'DRAFT',
  Paid = 'PAID',
  Unpaid = 'UNPAID',
}

export enum ESubscriptionStatus {
  Paid = 'PAID',
  Unpaid = 'UNPAID',
}
