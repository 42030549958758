import { memo } from 'react';
import { Box, Stack } from '@mui/material';
import { CircleOutlineIcon } from 'assets';

interface PricingFooterProps {}

const PricingFooter = memo((props: PricingFooterProps) => {
  return (
    <Box>
      <Stack direction={'row'} alignItems={'center'} gap={1} mb={1}>
        <CircleOutlineIcon />
        <p>Cancel your subscription anytime.</p>
      </Stack>

      <Stack direction={'row'} alignItems={'center'} gap={1} mb={1}>
        <CircleOutlineIcon />
        <p>Fast and secure payments using Stripe Payment Gateway.</p>
      </Stack>

      <Stack direction={'row'} alignItems={'center'} gap={1}>
        <CircleOutlineIcon />
        <p>Continue to use your remaining period after canceling your plan.</p>
      </Stack>
    </Box>
  );
});

export default PricingFooter;
