export const SET_IS_LOADING_REDUCER = 'SET_IS_LOADING_REDUCER';

export const SET_LOADING_AUTH_REDUCER = 'SET_LOADING_AUTH_REDUCER';

export const setIsLoadingReducer = (data: boolean) => {
  return {
    type: SET_IS_LOADING_REDUCER,
    data,
  };
};

export const setIsLoadingAuthReducer = (data: boolean) => {
  return {
    type: SET_LOADING_AUTH_REDUCER,
    data,
  };
};
