import { IDataRow, IEditDataRowFormData } from 'interfaces/demographics';
import classes from './styles.module.scss';
import { memo, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Input from 'components/Input';

interface ModalEditVariableProps {
  isOpen: boolean;
  data: IDataRow;
  onClose: () => void;
  onSubmit: (data: IEditDataRowFormData) => void;
}

const ModalEditVariable = memo((props: ModalEditVariableProps) => {
  const { isOpen, data, onClose, onSubmit } = props;

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      name: Yup.string().required('This field is required.'),
      unit: Yup.string().required('This field is required.'),
      tooltip: Yup.string().optional(),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IEditDataRowFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (!isOpen) {
      reset();
    } else {
      reset({ ...data });
    }
  }, [isOpen]);

  return (
    <Dialog transitionDuration={0.3} open={isOpen} onClose={onClose} className={classes.container}>
      <DialogTitle>Edit variable</DialogTitle>

      <DialogContent>
        <form id="form-edit-variable" onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Name"
            placeholder="Variable name"
            inputRef={register('name')}
            autoComplete="off"
            autoFocus={true}
            errorMessage={errors?.name?.message}
          />

          <Input label="Unit" placeholder="Unit" inputRef={register('unit')} autoComplete="off" autoFocus={true} errorMessage={errors?.unit?.message} />

          <Input
            label="Tooltip"
            placeholder="Tooltip"
            subLabel="Leave it empty if you don't want to show."
            inputRef={register('tooltip')}
            autoComplete="off"
            autoFocus={true}
            errorMessage={errors?.tooltip?.message}
          />
        </form>
      </DialogContent>

      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>

        <PrimaryButton type="submit" form="form-edit-variable">
          Submit
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

export default ModalEditVariable;
