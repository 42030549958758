import { Box } from '@mui/material';
import { ICategoryPricingPlan } from 'interfaces/plan';
import { memo } from 'react';
import classes from './styles.module.scss';

interface DescriptionCategoryProps{
    category: ICategoryPricingPlan;
}

const DescriptionCategory = memo((props: DescriptionCategoryProps) => {
    const { category } = props;
    return(
        <Box className={classes.descriptionBox} pb={1.5}>
            <div>{category.subtitle}</div>
            {category.description && (<div className={classes.subTitle}>{`${category.description}`}</div>)}
        </Box>
    );
});

export default DescriptionCategory;