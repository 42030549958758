import { memo } from 'react';
import classes from './styles.module.scss';
import Header from 'components/Header';

interface AuthorizedLayoutProps {
  children: React.ReactNode;
}

const AuthorizedLayout: React.FC<AuthorizedLayoutProps> = memo((props: AuthorizedLayoutProps) => {
  const { children } = props;

  return (
    <div className={classes.container}>
      <Header />
      <div id="authorized-layout-main" className={classes.main}>
        {children}
      </div>
    </div>
  );
});

export default AuthorizedLayout;
