import categoryPricingPlans from 'configs/plansNew';

const getCategoryPlan = (planId: number) => {
  for (const category of categoryPricingPlans) {
    if (category.listPlan.some((plan) => plan.id === planId)) {
      return category;
    }
  }
  return undefined;
};
const getPlanDetail = (planId: number) => {
  for (const category of categoryPricingPlans) {
    const plan = category.listPlan.find((p) => p.id === planId);
    if (plan) {
      return plan;
    }
  }
  return undefined; // Nếu không tìm thấy kế hoạch nào
};
const StripeService = { getCategoryPlan, getPlanDetail };

export default StripeService;
