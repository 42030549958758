import { memo, useCallback, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { Card, Grid, CardContent } from '@mui/material';
import { DataGrid, GridColDef, GridFilterModel, GridPaginationModel, GridSortModel, GridToolbar } from '@mui/x-data-grid';
import { IDataRow, IEditDataRowFormData } from 'interfaces/demographics';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ModalEditVariable from './ModalEditVariable';
import { IDataPagination, ISortItem } from 'interfaces/common';
import ToastService from 'services/toastService';
import ApiService from 'services/apiService';
import apiRoutes from 'configs/apiRoutes';
import Messages from 'configs/messages';
import { useDispatch } from 'react-redux';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';

interface CellType {
  row: IDataRow;
}

interface AdminEditTablePageProps {}

const AdminEditTablePage: React.FC<AdminEditTablePageProps> = memo((props: AdminEditTablePageProps) => {
  const dispatch = useDispatch();

  const columns: GridColDef[] = [
    {
      flex: 0.05,
      field: 'id',
      headerName: '#',
    },
    {
      flex: 0.2,
      minWidth: 150,
      field: 'name',
      headerName: 'Name',
    },
    {
      flex: 0.2,
      minWidth: 150,
      field: 'unit',
      headerName: 'Unit',
    },
    {
      flex: 0.2,
      minWidth: 150,
      field: 'tooltip',
      headerName: 'Tooltip',
    },
    {
      flex: 0.15,
      minWidth: 120,
      field: '',
      sortable: false,
      headerName: 'Action',
      renderCell: ({ row }: CellType) => {
        return (
          <div className={classes.actionButton}>
            <PrimaryButton onClick={() => setModalEditVariable(row)}>Edit</PrimaryButton>
          </div>
        );
      },
    },
  ];

  const [modalEditVariable, setModalEditVariable] = useState<IDataRow>(null);
  const [keyword, setKeyword] = useState<string>(null);
  const [sort, setSort] = useState<ISortItem>();
  const [datasetHistories, setDatasetHistories] = useState<IDataPagination<any>>();
  const [loadingDatasetHistory, setLoadingDatasetHistory] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = useCallback(
    (value?: { limit?: number; page?: number; keyword?: string; sort?: ISortItem }) => {
      const params: any = {
        limit: value?.limit || datasetHistories?.meta?.limit || 10,
        page: value?.page || datasetHistories?.meta?.page || 1,
        keyword: keyword,
        sortedField: sort?.sortedField,
        isDescending: sort?.isDescending,
      };
      if (value?.sort !== undefined) {
        params.sortedField = value?.sort?.sortedField;
        params.isDescending = value?.sort?.isDescending;
      }
      if (value?.keyword !== undefined) {
        params.keyword = value?.keyword || undefined;
      }

      setLoadingDatasetHistory(true);
      ApiService.GET(apiRoutes.interdemField.default, { ...params })
        .then((res) => {
          setDatasetHistories({
            data: res?.data,
            meta: res?.meta,
          });
        })
        .catch(() => ToastService.error(Messages.error.default))
        .finally(() => setLoadingDatasetHistory(false));
    },
    [datasetHistories, keyword, sort]
  );

  const onPaginationChange = useCallback(
    (val: GridPaginationModel) => {
      fetchData({
        page: val.page + 1,
        limit: val.pageSize,
      });
    },
    [fetchData]
  );

  const onSortChange = useCallback(
    (val: GridSortModel) => {
      const sortQuery: ISortItem =
        val?.length > 0 ? { sortedField: val?.[0]?.field ?? 'null', isDescending: val?.[0]?.sort === 'asc' ? false : true } : null;
      setSort(sortQuery);
      fetchData({
        sort: sortQuery,
      });
    },
    [fetchData]
  );

  const onFilterChange = useCallback(
    (val: GridFilterModel) => {
      setKeyword(val?.quickFilterValues?.[0] ?? '');
      fetchData({
        keyword: val?.quickFilterValues?.[0] ?? '',
      });
    },
    [fetchData]
  );

  const onCloseModal = () => {
    setModalEditVariable(null);
  };

  const onSubmit = (data: IEditDataRowFormData) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.PATCH(apiRoutes.interdemField.update.replace(':id', data.id.toString()), {
      name: data?.name ?? undefined,
      unit: data?.unit ?? undefined,
      tooltip: data?.tooltip ?? undefined,
    })
      .then((response) => {
        onCloseModal();
        setDatasetHistories({
          ...datasetHistories,
          data: datasetHistories?.data?.map((item) => {
            if (item?.id === response?.data?.id) {
              return response?.data;
            }
            return item;
          }),
        });
      })
      .catch((error) => {
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ borderRadius: '10px' }}>
            <p className={classes.title}>Edit table's variables</p>

            <CardContent className={classes.content}>
              <DataGrid<any>
                autoHeight
                pagination
                columns={columns}
                rows={datasetHistories?.data ?? []}
                rowCount={datasetHistories?.meta?.itemCount || 0}
                disableRowSelectionOnClick
                disableColumnSelector
                paginationMode="server"
                filterMode="server"
                sortingMode="server"
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                disableColumnFilter
                onPaginationModelChange={onPaginationChange}
                onFilterModelChange={onFilterChange}
                onSortModelChange={onSortChange}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                loading={loadingDatasetHistory}
                sx={{
                  '*': {
                    outline: 'none !important',
                  },
                  '.MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 600,
                  },
                  '.MuiCircularProgress-svg': {
                    color: 'var(--primary)',
                  },
                  '.MuiButtonBase-root': {
                    color: 'var(--black)',
                  },
                  '.MuiInputBase-root::after': {
                    borderColor: 'var(--black)',
                  },
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {modalEditVariable ? <ModalEditVariable isOpen={!!modalEditVariable} data={modalEditVariable} onClose={onCloseModal} onSubmit={onSubmit} /> : null}
    </div>
  );
});

export default AdminEditTablePage;
