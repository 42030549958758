import { Fragment, memo, useEffect, useMemo } from 'react';
import classes from './styles.module.scss';
import Input from 'components/Input';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import Regexes from 'configs/regexes';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import ToastService from 'services/toastService';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Messages from 'configs/messages';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiService from 'services/apiService';
import apiRoutes from 'configs/apiRoutes';
import { push } from 'connected-react-router';
import CryptoJS from 'crypto-js';
import { appRoutes } from 'routers/appRoutes';
import { Link } from 'react-router-dom';
import { DefaultLogo } from 'assets';
import { EStatusCode } from 'configs/enums';

interface IRegisterFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
}

interface RegisterPageProps {}

const RegisterPage: React.FC<RegisterPageProps> = memo((props: RegisterPageProps) => {
  const dispatch = useDispatch();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required('Please enter your first name.'),
        lastName: Yup.string().required('Please enter your last name.'),
        email: Yup.string().required('Please enter your email.').matches(Regexes.email, 'Please enter a valid email.'),
        phone: Yup.lazy((value) => (!value ? Yup.string() : Yup.string().matches(Regexes.phoneNumber, 'Please enter a valid phone number.'))),
        password: Yup.string()
          .required('Please enter your password.')
          .matches(Regexes.password, 'Password must be least 8 characters including an upper case, an lower case, a number, and a special character.'),
        confirmPassword: Yup.string()
          .required('Please confirm your password.')
          .oneOf([Yup.ref('password'), null], 'Confirm password does not match.'),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IRegisterFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const watchPassword = watch('password');
  const watchConfirmPassword = watch('confirmPassword');

  useEffect(() => {
    if (watchPassword !== watchConfirmPassword && watchConfirmPassword !== '') {
      setError('confirmPassword', {
        type: 'match',
        message: 'Confirm password does not match',
      });
    } else if (watchPassword === watchConfirmPassword && watchConfirmPassword !== '') {
      clearErrors('confirmPassword');
    }
  }, [watchConfirmPassword, watchPassword]);

  const onRegister = async (data: IRegisterFormData) => {
    dispatch(setIsLoadingReducer(true));
    await ApiService.POST(apiRoutes.user.register, {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.phone,
      password: CryptoJS.AES.encrypt(data?.password, process.env.REACT_APP_AES_KEY).toString(),
    })
      .then(() => {
        dispatch(setIsLoadingReducer(false));
        dispatch(
          push({
            pathname: appRoutes.fullyAllowed.checkEmail,
            search: `&email=${encodeURIComponent(data?.email)}`,
          })
        );
        ToastService.success(Messages.success.created);
      })
      .catch((error) => {
        console.log(error);
        ToastService.error(error?.response?.status === EStatusCode.BadRequest ? Messages.error.existedEmail : Messages.error.default);
        dispatch(setIsLoadingReducer(false));
      });
  };

  return (
    <Fragment>
      <Link to={appRoutes.private.home}>
        <img className={classes.logoImage} src={DefaultLogo} alt="Logo" />
      </Link>

      <p className={classes.title}>Register</p>

      <form onSubmit={handleSubmit(onRegister)}>
        <Row>
          <Col sm={6}>
            <Input
              label="First name"
              inputRef={register('firstName')}
              type="text"
              placeholder="Your first name"
              autoComplete="given-name"
              errorMessage={errors?.firstName?.message}
            />
          </Col>
          <Col sm={6}>
            <Input
              label="Last name"
              inputRef={register('lastName')}
              type="text"
              placeholder="Your last name"
              autoComplete="family-name"
              errorMessage={errors?.lastName?.message}
            />
          </Col>
        </Row>

        <Input
          label="Email"
          inputRef={register('email')}
          type="text"
          placeholder="Your email"
          autoComplete="email"
          errorMessage={errors?.email?.message}
        />

        <Input
          label="Phone number"
          inputRef={register('phone')}
          type="text"
          placeholder="Your phone number"
          autoComplete="tel"
          isOptional
          errorMessage={errors?.phone?.message}
        />

        <Input label="Password" inputRef={register('password')} type="password" placeholder="Your password" errorMessage={errors?.password?.message} />

        <Input
          className="mb-4"
          label="Confirm password"
          inputRef={register('confirmPassword')}
          type="password"
          placeholder="Confirm your password"
          errorMessage={errors?.confirmPassword?.message}
        />

        <PrimaryButton className="mb-4" type="submit" fullWidth>
          Register
        </PrimaryButton>

        <div className={classes.loginContainer}>
          <p>Already have an account?</p>
          <Link to={appRoutes.public.login}>Login</Link>
        </div>
      </form>
    </Fragment>
  );
});

export default RegisterPage;
