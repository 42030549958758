import { ICategoryPricingPlan } from 'interfaces/plan';
import { CategoryPlan, EPlan } from './enums';
import { BasicPricingImage, EnterprisePricingImage, StandardPricingImage, YearPricingImage } from 'assets';

const categoryPricingPlans: ICategoryPricingPlan[] = [
  {
    id: CategoryPlan.All,
    title: "Premium Package",
    subtitle: "This package includes everything – ie all countries and all of China",
    description: null,
    listPlan: [
      {
        id: EPlan.DailyPremium,
        title: 'Daily Premium',
        subtitle: 'A simple start for everyone',
        description: 'This plan costs $8 per day',
        price: 8,
        durationPlaceholder: 'day',
        imageSource: BasicPricingImage,
      },
      {
        id: EPlan.WeeklyPremium,
        title: 'Weekly Premium',
        subtitle: 'For medium businesses',
        description: 'This plan costs $40 per week',
        price: 40,
        durationPlaceholder: 'week',
        imageSource: StandardPricingImage,
      },
      {
        id: EPlan.MonthlyPremium,
        title: 'Monthly Premium',
        subtitle: 'For big organizations',
        description: 'This plan costs $150 per month',
        price: 150,
        durationPlaceholder: 'month',
        imageSource: EnterprisePricingImage,
      },
      {
        id: EPlan.YearlyPremium,
        title: 'Yearly Premium',
        subtitle: 'For big organizations',
        description: 'This plan costs $1600 per year',
        price: 1600,
        durationPlaceholder: 'year',
        imageSource: YearPricingImage,
      },
    ]
  },
  {
    id: CategoryPlan.AllExcludingChina,
    title: "Global Package",
    subtitle: "This package is all countries INCLUDING China National Total ",
    description: "(But not China National Urban)",
    listPlan: [
      {
        id: EPlan.DailyPro,
        title: 'Daily Global',
        subtitle: 'A simple start for everyone',
        description: 'This plan costs $5 per day',
        price: 5,
        durationPlaceholder: 'day',
        imageSource: BasicPricingImage,
      },
      {
        id: EPlan.WeeklyPro,
        title: 'Weekly Global',
        subtitle: 'For medium businesses',
        description: 'This plan costs $25 per week',
        price: 25,
        durationPlaceholder: 'week',
        imageSource: StandardPricingImage,
      },
      {
        id: EPlan.MonthlyPro,
        title: 'Monthly Global',
        subtitle: 'For big organizations',
        description: 'This plan costs $100 per month',
        price: 100,
        durationPlaceholder: 'month',
        imageSource: EnterprisePricingImage,
      },
      {
        id: EPlan.YearlyPro,
        title: 'Yearly Global',
        subtitle: 'For big organizations',
        description: 'This plan costs $1100 per year',
        price: 1100,
        durationPlaceholder: 'year',
        imageSource: YearPricingImage,
      },
    ]
  },
  {
    id: CategoryPlan.China,
    title: "China Package",
    subtitle: "This package is all Provinces and Prefectures and National Total and  National Urban",
    description: null,
    listPlan: [
      {
        id: EPlan.DailyBasic,
        title: 'Daily China',
        subtitle: 'A simple start for everyone',
        description: 'This plan costs $5 per day',
        price: 5,
        durationPlaceholder: 'day',
        imageSource: BasicPricingImage,
      },
      {
        id: EPlan.WeeklyBasic,
        title: 'Weekly China',
        subtitle: 'For medium businesses',
        description: 'This plan costs $25 per week',
        price: 25,
        durationPlaceholder: 'week',
        imageSource: StandardPricingImage,
      },
      {
        id: EPlan.MonthlyBasic,
        title: 'Monthly China',
        subtitle: 'For big organizations',
        description: 'This plan costs $100 per month',
        price: 100,
        durationPlaceholder: 'month',
        imageSource: EnterprisePricingImage,
      },
      {
        id: EPlan.YearlyBasic,
        title: 'Yearly China',
        subtitle: 'For big organizations',
        description: 'This plan costs $1100 per year',
        price: 1100,
        durationPlaceholder: 'year',
        imageSource: YearPricingImage,
      },
    ]
  },
];

export default categoryPricingPlans;
