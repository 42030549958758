import { Fragment, memo, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import Draggable from 'react-draggable';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ModalChangeDataTable from './ModalChangeDataTable';
import { IChartData, IData, IDataChange } from 'interfaces/demographics';
import DataTableChart from './DataTableChart';
import SecondaryButton from 'components/Buttons/SecondaryButton';

interface IModalChartItem {
  chartData: IChartData;
  coordinate: [number, number];
}

interface ModalsChartDataTableProps {
  chartsData: IChartData[];
  yearRangeViewStep: number[];
  yearRange: [number, number];
  onSubmit: (data: IDataChange) => void;
  onPopChart: (topicId: number, variableId: number) => void;
}

const ModalsChartDataTable = memo((props: ModalsChartDataTableProps, ref: any) => {
  const { chartsData, yearRangeViewStep, yearRange, onSubmit, onPopChart } = props;

  const [modalChartItems, setModalChartItems] = useState<IModalChartItem[]>([]);
  const [changeChartItem, setChangeChartItem] = useState<IModalChartItem>(null);

  useEffect(() => {
    setModalChartItems(
      chartsData?.map((chartData) => ({
        topicId: chartData?.baseCaseData?.topicId,
        variableId: chartData?.baseCaseData?.variableId,
        chartData: {
          baseCaseData: chartData?.baseCaseData,
          revisedData: chartData?.revisedData,
          forecastYear: chartData?.forecastYear,
        },
        coordinate: [(modalChartItems.length + 1) * 20, (modalChartItems.length + 1) * 20],
      }))
    );
  }, [chartsData]);

  const onCloseModal = () => {
    setChangeChartItem(null);
  };

  const _onSubmit = (data: IData) => {
    onSubmit({
      topicId: changeChartItem?.chartData?.baseCaseData.topicId,
      variableId: changeChartItem?.chartData?.baseCaseData?.variableId,
      reversedDataChanged: data,
    });
    onCloseModal();    
  };

  const onOpenModal = (variableId: number) => {
    const chart = modalChartItems?.find((item) => item?.chartData?.baseCaseData?.variableId === variableId);
    if (chart) {
      setChangeChartItem(chart);
    }
  };

  const bringModalToFront = (variableId: number) => {
    const modalIndex = modalChartItems?.findIndex((item) => item?.chartData?.baseCaseData?.variableId === variableId);
    const rearrangeModalsList = [...modalChartItems];
    if (modalIndex >= 0) {
      rearrangeModalsList.push(rearrangeModalsList.splice(modalIndex, 1)[0]);
      setModalChartItems(rearrangeModalsList);
    }
  };

  return (
    <Fragment>
      {modalChartItems?.map((chartItem) => {
        const variableId = chartItem?.chartData?.baseCaseData?.variableId;
        const topicId = chartItem?.chartData?.baseCaseData?.topicId;
        return (
          <div key={variableId} onClick={() => bringModalToFront(variableId)} onTouchStart={() => bringModalToFront(variableId)}>
            <Draggable defaultPosition={{ x: chartItem?.coordinate[0], y: chartItem?.coordinate[1] }}>
              <div className={classes.modalDialog}>
                <div className={classes.modalHeader}>
                  {chartItem?.chartData?.revisedData?.changeable === true ? (
                    <PrimaryButton
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event?.stopPropagation();
                        onOpenModal(variableId);
                      }}
                      onTouchStart={(event: React.TouchEvent<HTMLButtonElement>) => {
                        event?.stopPropagation();
                        onOpenModal(variableId);
                      }}
                    >
                      Change
                    </PrimaryButton>
                  ) : null}

                  <SecondaryButton
                    className={classes.closeButton}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event?.stopPropagation();
                      onPopChart(topicId, variableId);
                    }}
                    onTouchStart={(event: React.TouchEvent<HTMLButtonElement>) => {
                      event?.stopPropagation();
                      onPopChart(topicId, variableId);
                    }}
                  >
                    Close
                  </SecondaryButton>
                </div>

                <div className={classes.modalBody}>
                  <div className={classes.tableWrapper}>
                    <DataTableChart
                      title={chartItem?.chartData?.baseCaseData?.name ?? chartItem?.chartData?.revisedData?.name}
                      unit={chartItem?.chartData?.baseCaseData?.unit ?? chartItem?.chartData?.revisedData?.unit}
                      isPercentage={chartItem?.chartData?.baseCaseData?.isPercentage}
                      dataSeries={[chartItem?.chartData?.baseCaseData?.data, chartItem?.chartData?.revisedData?.data]}
                      yearRange={yearRange}
                      forecastYear={changeChartItem?.chartData?.forecastYear}
                    />
                  </div>
                </div>
              </div>
            </Draggable>
          </div>
        );
      })}

      {changeChartItem ? (
        <ModalChangeDataTable
          isOpen={!!changeChartItem}
          data={{
            chartData: changeChartItem?.chartData,
            yearRange: yearRange,
            yearRangeViewStep: yearRangeViewStep,
          }}
          onClose={onCloseModal}
          onSubmit={_onSubmit}
        />
      ) : null}
    </Fragment>
  );
});

export default ModalsChartDataTable;
