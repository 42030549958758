import { Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import { memo } from 'react';
import classes from './styles.module.scss';
import { appRoutes } from 'routers/appRoutes';
import { Link, matchPath, NavLink } from 'react-router-dom';
import { DefaultLogo } from 'assets';
import clsx from 'clsx';

interface SidebarProps {
  isOpen: boolean;
  handleDrawerToggle: () => void;
}

interface AdminSidebarRoute {
  name: string;
  path: string;
}
const adminSidebarRoutes: AdminSidebarRoute[] = [
  {
    name: 'Users',
    path: appRoutes.admin.users,
  },
  {
    name: 'Import dataset',
    path: appRoutes.admin.importDataset,
  },
  {
    name: 'Edit table fields',
    path: appRoutes.admin.editTable,
  }
];

const Sidebar: React.FC<SidebarProps> = memo((props: SidebarProps) => {
  const { isOpen, handleDrawerToggle } = props;

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const activeRoute = (routeName: string) => {
    const match = matchPath(window.location.pathname, {
      path: routeName,
      exact: false,
    });
    return !!match;
  };

  return (
    <div className={classes.container}>
      <Drawer
        variant={!isMobile ? 'permanent' : 'temporary'}
        anchor="left"
        open={isMobile ? isOpen : true}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: isMobile, // Better open performance on mobile.
        }}
      >
        <Link to={appRoutes.private.home} className={classes.logo}>
          <img src={DefaultLogo} alt="Logo" />
        </Link>
        <div>
          <List className={classes.list}>
            {adminSidebarRoutes.map((route, key) => {
              const active: boolean = activeRoute(route.path);
              return (
                <NavLink to={route.path} className={classes.item} activeClassName="active" key={key}>
                  <ListItem
                    button
                    className={clsx(classes.itemLink, {
                      [classes.itemLinkActive]: active,
                    })}
                  >
                    <ListItemText
                      primary={route.name}
                      className={clsx(classes.itemText, {
                        [classes.itemTextActive]: active,
                      })}
                      disableTypography={true}
                    />
                  </ListItem>
                </NavLink>
              );
            })}
          </List>
        </div>
      </Drawer>
    </div>
  );
});

export default Sidebar;
