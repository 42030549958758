import produce from 'immer';
import { IDataControl } from 'interfaces/demographics';
import { SET_DATA_CONTROL_REDUCER } from './actionTypes';

export interface IDataControlState {
  dataControl: IDataControl;
}

const initialState: IDataControlState = {
  dataControl: null,
};

export const dataControlReducer = (state: IDataControlState = initialState, action: { type: string; data: any }) =>
  produce(state, (draft) => {
    switch (action?.type) {
      case SET_DATA_CONTROL_REDUCER:
        draft.dataControl = action?.data;
        break;
      default:
        return state;
    }
  });
