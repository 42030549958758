import TokenService from './tokenService';

interface LoginData {
  token: string;
}

const login = (data: LoginData) => {
  TokenService.setToken(data?.token);
};

const logout = () => {
  localStorage.clear();
};

const AuthService = { login, logout };

export default AuthService;
