import { ButtonHTMLAttributes, memo } from 'react';
import classes from './styles.module.scss';
import clsx from 'clsx';

interface SecondaryButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  onClick?: (...args: any) => void;
  [key: string]: any;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = memo((props: SecondaryButtonProps) => {
  const { children, className, style, fullWidth, onClick, ...rest } = props;

  return (
    <button className={clsx(classes.container, className, { 'w-100': fullWidth })} style={style} onClick={onClick} {...rest}>
      {children}
    </button>
  );
});

export default SecondaryButton;
