import { ENodeEnv } from 'configs/enums';
import { History } from 'history';
// import loggerMiddleware from 'redux/middlewares/logger';
import routerMiddleware from 'redux/middlewares/router';
import sagaMiddleware from 'redux/middlewares/saga';

const createMiddleware = (history: History) => {
  if (process.env.REACT_APP_ENVIRONMENT !== ENodeEnv.Production) {
    return [
      routerMiddleware(history),
      // loggerMiddleware,
      sagaMiddleware,
    ];
  } else {
    return [routerMiddleware(history), sagaMiddleware];
  }
};

export default createMiddleware;
