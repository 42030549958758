import produce from 'immer';
import { IUser } from 'interfaces/user';
import { SET_LOGOUT_USER_REDUCER, SET_USER_REDUCER } from './actionTypes';

export interface IUserState {
  user: IUser;
}

const initialState: IUserState = {
  user: null,
};

export const userReducer = (state: IUserState = initialState, action: { type: string; data: any }) =>
  produce(state, (draft) => {
    switch (action?.type) {
      case SET_USER_REDUCER:
        draft.user = action?.data;
        break;
      case SET_LOGOUT_USER_REDUCER:
        draft.user = null;
        break;
      default:
        return state;
    }
  });
