import { IDataRow, IEditUser } from 'interfaces/demographics';
import classes from './styles.module.scss';
import { memo, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Input from 'components/Input';
import { EUserType } from 'configs/enums';
import { Col, Row } from 'reactstrap';
import Regexes from 'configs/regexes';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import categoryPricingPlans from 'configs/plansNew';
import { ICategoryPricingPlan } from 'interfaces/plan';
import CustomDatePicker from 'components/DatePicker';
import dayjs from 'dayjs';

interface ModalEditVariableProps {
  isOpen: boolean;
  data: IDataRow;
  onClose: () => void;
  onSubmit: (data: IEditUser) => void;
  setCategorySelected: (data: number) => void;
  categorySelected: number
}

const ModalEditVariable = memo((props: ModalEditVariableProps) => {
  const { isOpen, data, onClose, onSubmit, setCategorySelected, categorySelected } = props;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required('Please enter your first name.'),
        lastName: Yup.string().required('Please enter your last name.'),
        email: Yup.string().required('Please enter your email.').matches(Regexes.email, 'Please enter a valid email.'),
        phone: Yup.lazy((value) => (!value || data ? Yup.string() : Yup.string().matches(Regexes.phoneNumber, 'Please enter a valid phone number.'))),
        password: Yup.lazy(() => (data ? Yup.string() : Yup.string().required('Please enter your password.').matches(Regexes.password, 'Password must be least 8 characters including an upper case, an lower case, a number, and a special character.'))),
      }),
    [data]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm<IEditUser>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (!isOpen) {
      reset();
    } else {
      reset({ ...data });
    }
  }, [isOpen]);

  useEffect(() => {
    if (data) {
      setValue('endDate', dayjs(data?.subscription?.endDate))
      setValue('startDate', dayjs(data?.subscription?.startDate))
      setValue('phone', data?.phone ?? '')
      setCategorySelected(data?.subscription?.categoryId ?? 1)
    }
  }, [data]);

  const handleChange = (event: SelectChangeEvent) => {
    setCategorySelected(+event.target.value);
  };

  return (
    <Dialog transitionDuration={0.3} open={isOpen} onClose={onClose} className={classes.container}>
      <DialogTitle>User</DialogTitle>

      <DialogContent>
        <form id="form-edit-variable" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col sm={6}>
              <Input
                label="First Name"
                placeholder="First Name"
                inputRef={register('firstName')}
                autoComplete="off"
                errorMessage={errors?.firstName?.message}
              />
            </Col>
            <Col sm={6}>
              <Input
                label="Last Name"
                placeholder="Last Name"
                inputRef={register('lastName')}
                autoComplete="off"
                errorMessage={errors?.lastName?.message}
              />
            </Col>
          </Row>
          <Input
            label="Email"
            placeholder="Email"
            inputRef={register('email')}
            autoComplete="off"
            disabled={!!data}
            errorMessage={errors?.email?.message}
          />

          <Input
            label="Password"
            placeholder="Password"
            inputRef={register('password')}
            type="password"
            autoComplete="off"
            errorMessage={errors?.password?.message}
          />
          <Input
            label="Phone"
            placeholder="Phone"
            inputRef={register('phone')}
            autoComplete="off"
            errorMessage={errors?.phone?.message}
          />
          {
            (data?.type === EUserType.Custom) && (
              <>
                <label style={{ fontWeight: '600' }}>Package</label>
                <Select
                  className={classes.packageSelect}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={categorySelected.toString()}
                  onChange={handleChange}
                  name="package"
                >
                  {categoryPricingPlans?.map((category: ICategoryPricingPlan, categoryIndex: number) => (
                    <MenuItem value={category.id} key={categoryIndex}>{category.title}</MenuItem>
                  ))}
                </Select>

                <Row className="mt-4">
                  <Col sm={6}>
                    <CustomDatePicker
                      label="Start date"
                      name="startDate"
                      control={control}
                      errorMessage={errors?.startDate?.message as string}
                    />
                  </Col>
                  <Col sm={6}>
                    <CustomDatePicker
                      label="End Date"
                      name="endDate"
                      control={control}
                      errorMessage={errors?.endDate?.message as string}
                    />
                  </Col>
                </Row>
              </>
            )
          }
        </form>
      </DialogContent>

      <DialogActions sx={{paddingTop: "20px !important"}}>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>

        <PrimaryButton type="submit" form="form-edit-variable">
          Submit
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

export default ModalEditVariable;
