import { Fragment, memo, useMemo } from 'react';
import classes from './styles.module.scss';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Regexes from 'configs/regexes';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import AuthService from 'services/authService';
import Input from 'components/Input';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ToastService from 'services/toastService';
import ApiService from 'services/apiService';
import Messages from 'configs/messages';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import apiRoutes from 'configs/apiRoutes';
import CryptoJS from 'crypto-js';
import { appRoutes } from 'routers/appRoutes';
import { DefaultLogo } from 'assets';
import { IUser } from 'interfaces/user';
import { EStatusCode } from 'configs/enums';
import { setUserReducer } from 'redux/reducers/User/actionTypes';

interface ILoginFormData {
  email: string;
  password: string;
}

interface LoginPageProps {}

const LoginPage: React.FC<LoginPageProps> = memo((props: LoginPageProps) => {
  const dispatch = useDispatch();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().required('Please enter your email.').matches(Regexes.email, 'Please enter a valid email.'),
        password: Yup.string().required('Please enter your password.'),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const onSubmit = async (data: ILoginFormData) => {
    dispatch(setIsLoadingReducer(true));
    await ApiService.POST(apiRoutes.user.login, {
      email: data?.email,
      password: CryptoJS.AES.encrypt(data?.password, process.env.REACT_APP_AES_KEY).toString(),
    })
      .then((response) => {
        dispatch(setIsLoadingReducer(false));
        const user: IUser = response?.data?.user;
        if (user?.isVerified) {
          AuthService.login(response?.data);
          dispatch(setUserReducer(user));
          dispatch(push(appRoutes.private.home));
        } else {
          dispatch(
            push({
              pathname: appRoutes.fullyAllowed.checkEmail,
              search: `&email=${encodeURIComponent(data?.email)}`,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(setIsLoadingReducer(false));
        switch (error?.response?.status) {
          case EStatusCode.NotFound:
            return ToastService.error(Messages.error.wrongEmail);
          case EStatusCode.BadRequest:
            return ToastService.error(Messages.error.wrongPassword);
          case EStatusCode.AccessDenied:
            return ToastService.error(Messages.error.suspendedAccount);
          default:
            return ToastService.error(Messages.error.default);
        }
      });
  };

  return (
    <Fragment>
      <Link to={appRoutes.private.home}>
        <img className={classes.logoImage} src={DefaultLogo} alt="Logo" />
      </Link>

      <p className={classes.title}>Login</p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Email"
          inputRef={register('email')}
          type="text"
          placeholder="Your email"
          autoComplete="email"
          errorMessage={errors?.email?.message}
        />

        <Input
          label="Password"
          inputRef={register('password')}
          type="password"
          placeholder="Your password"
          autoComplete="password"
          errorMessage={errors?.password?.message}
        />

        <Link className={classes.forgotPassword} to={appRoutes.fullyAllowed.forgotPassword}>
          Forgot your password?
        </Link>

        <PrimaryButton className="mb-4" type="submit" fullWidth>
          Login
        </PrimaryButton>

        <div className={classes.registerContainer}>
          <p>Don't have an account?</p>
          <Link to={appRoutes.public.register}>Create account</Link>
        </div>
      </form>
    </Fragment>
  );
});

export default LoginPage;
