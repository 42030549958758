import { forwardRef, memo, useState } from 'react';
import clsx from 'clsx';
import { Input as ReactstrapInput, InputProps as ReactstrapInputProps } from 'reactstrap';
import classes from './styles.module.scss';
import { UseFormRegisterReturn } from 'react-hook-form';
import { EyeHideIcon, EyeShowIcon } from 'assets';

interface InputProps extends ReactstrapInputProps {
  className?: string;
  label?: string;
  subLabel?: string;
  inputRef?: UseFormRegisterReturn;
  errorMessage?: string;
  isOptional?: boolean;
  disabled?: boolean
  [key: string]: any;
}

const Input: React.FC<InputProps & { ref?: React.Ref<HTMLInputElement> }> = memo(
  forwardRef((props: InputProps, ref: any) => {
    const { className, label, subLabel, inputRef, errorMessage, type, isOptional, disabled, ...rest } = props;
    const isPassword = type === 'password';
    const isDisabled = !!disabled;
    const { ref: formRef, ...inputProps } = inputRef || { ref: null };

    const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

    return (
      <div
        className={clsx(classes.container, className, {
          [classes.isPassword]: isPassword,
          [classes.isDisabled]: isDisabled,
        })}
      >
        {label ? (
          <label>
            {label} {isOptional ? <span>(Optional)</span> : null}
          </label>
        ) : null}

        <ReactstrapInput
          {...inputProps}
          key={inputRef?.name}
          innerRef={formRef ?? ref}
          type={isPassword ? (isShowPassword ? 'text' : 'password') : type ?? 'text'}
          disabled={isDisabled}
          {...rest}
        />

        {type === 'password' ? (
          <div className={classes.passwordIcon}>
            {isShowPassword ? (
              <EyeShowIcon
                onClick={() => {
                  setIsShowPassword(!isShowPassword);
                }}
              />
            ) : (
              <EyeHideIcon
                onClick={() => {
                  setIsShowPassword(!isShowPassword);
                }}
              />
            )}
          </div>
        ) : null}

        {subLabel ? <p className={classes.subLabel}>{subLabel}</p> : null}

        {errorMessage ? <p className={classes.errorMessage}>{errorMessage}</p> : null}
      </div>
    );
  })
);

export default Input;
