import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { IStatusState, statusReducer } from './Status';
import { History } from 'history';
import { IUserState, userReducer } from './User';
import { dataControlReducer, IDataControlState } from './DataControl';

export interface IReducer {
  status: IStatusState;
  user: IUserState;
  dataControl: IDataControlState;
  router: {
    location: {
      pathname: string;
      search: string;
      hash: string;
    };
    action: string;
  };
}

const createRootReducer = (history: History) => {
  const reducers = combineReducers({
    status: statusReducer,
    user: userReducer,
    dataControl: dataControlReducer,
    router: connectRouter(history),
  });
  return reducers;
};

export default createRootReducer;
