import apiRoutes from 'configs/apiRoutes';
import Messages from 'configs/messages';
import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_DATA_CONTROL_REQUEST, setDataControlReducer } from 'redux/reducers/DataControl/actionTypes';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiService from 'services/apiService';
import ToastService from 'services/toastService';

function* requestGetDataControl() {
  try {
    yield put(setIsLoadingReducer(true));
    const response = yield call(ApiService.GET, apiRoutes.dataControl.default);
    yield put(setDataControlReducer(response?.data));
  } catch (error: any) {
    yield console.log(error);
    yield ToastService.error(Messages.error.default);
  } finally {
    yield put(setIsLoadingReducer(false));
  }
}

function* getDataControl() {
  yield takeLatest(GET_DATA_CONTROL_REQUEST, requestGetDataControl);
}

export default getDataControl;
