import { IUser } from 'interfaces/user';

export const GET_ME_REQUEST = 'GET_ME_REQUEST';

export const SET_USER_REDUCER = 'SET_USER_REDUCER';

export const SET_LOGOUT_USER_REDUCER = 'SET_LOGOUT_USER_REDUCER';

export const getMeRequest = () => {
  return {
    type: GET_ME_REQUEST,
  };
};

export const setUserReducer = (data: IUser) => {
  return {
    type: SET_USER_REDUCER,
    data,
  };
};

export const setLogoutUserReducer = () => {
  return {
    type: SET_LOGOUT_USER_REDUCER,
  };
};
