import { Fragment, memo } from 'react';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import classes from './styles.module.scss';
import { DatePicker, DatePickerProps, DateView, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller } from 'react-hook-form';

interface CustomDatePickerProps extends StateManagerProps {
  label?: string;
  errorMessage?: string;
  views?: DateView[];
  name?: string;
  control?: any;
  datePickerProps?: DatePickerProps<any, any>;
  [key: string]: any;
}

const CustomDatePicker = memo((props: CustomDatePickerProps) => {
  const { label, views, errorMessage, name, control, datePickerProps } = props;

  return (
    <Fragment>
      {control ? (
        <div className={classes.container}>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <div className={classes.inputWrapper}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {label ? <label>{label}</label> : null}
                  <DatePicker
                    {...datePickerProps}
                    value={field.value ?? null}
                    inputRef={field.ref}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    className={classes.datePicker}
                    views={views}
                    slotProps={{
                      popper: {
                        sx: {
                          '.MuiDateCalendar-root': { height: 'unset' },
                          '.Mui-selected': { backgroundColor: 'var(--primary) !important' },
                        },
                      },
                      textField: {
                        className: `${classes.datePickerInput}`,
                        onKeyDown: (event) => event.preventDefault(),
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            )}
          />
          {errorMessage ? <p>{errorMessage}</p> : null}
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.inputWrapper}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {label ? <label>{label}</label> : null}
              <DatePicker
                className={classes.datePicker}
                views={views}
                slotProps={{
                  popper: {
                    sx: {
                      '.MuiDateCalendar-root': { height: 'unset' },
                      '.Mui-selected': { backgroundColor: 'var(--primary)' },
                    },
                  },
                  textField: {
                    className: `${classes.datePickerInput}`,
                  },
                }}
              />
            </LocalizationProvider>
          </div>
          {errorMessage ? <p>{errorMessage}</p> : null}
        </div>
      )}
    </Fragment>
  );
});

export default CustomDatePicker;
