import { IDataControl } from 'interfaces/demographics';

export const GET_DATA_CONTROL_REQUEST = 'GET_DATA_CONTROL_REQUEST';

export const SET_DATA_CONTROL_REDUCER = 'SET_DATA_CONTROL_REDUCER';

export const getDataControlRequest = () => {
  return {
    type: GET_DATA_CONTROL_REQUEST,
  };
};

export const setDataControlReducer = (data: IDataControl) => {
  return {
    type: SET_DATA_CONTROL_REDUCER,
    data,
  };
};
