import { memo } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthService from 'services/authService';
import TokenService from 'services/tokenService';
import { appRoutes } from './appRoutes';
import { useSelector } from 'react-redux';
import { IReducer } from 'redux/reducers';
import { EUserType } from 'configs/enums';

interface MiddlewareProps {
  component: React.ElementType;
  layout: React.ElementType;
  layoutNonAuthorizedLayout?: React.ElementType;
  isAuthProtected: boolean;
  isAdminProtected: boolean;
  isFullyAllowed: boolean;
  [key: string]: any;
  isBlankLayout?: boolean;
}

const Middleware: React.FC<MiddlewareProps> = memo((props: MiddlewareProps) => {
  const { component: Component, layout: Layout, layoutNonAuthorizedLayout: LayoutNonAuthorizedLayout, isAuthProtected, isBlankLayout, isAdminProtected, isFullyAllowed, path, ...rest } = props;

  const { user } = useSelector((state: IReducer) => state?.user);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAdminProtected && user?.type !== EUserType.Admin) {
          return <Redirect to={{ pathname: appRoutes.private.home, state: { from: props.location } }} />;
        } else if (isAuthProtected && !TokenService.getToken() && !isFullyAllowed) {
          AuthService.logout();
          return <Redirect to={{ pathname: appRoutes.public.login, state: { from: props.location } }} />;
        } else if (!isAuthProtected && TokenService.getToken() && !isFullyAllowed) {
          return <Redirect to={{ pathname: appRoutes.private.home, state: { from: props.location } }} />;
        } else if(user?.isAcceptCondition && appRoutes.private.acceptCondition === path){
          return <Redirect to={{ pathname: appRoutes.private.home, state: { from: props.location } }} />;
        }else if(user && !user?.isAcceptCondition && appRoutes.private.acceptCondition !== path ){
          return <Redirect to={{ pathname: appRoutes.private.acceptCondition, state: { from: props.location } }} />;
        }else{
          if(isBlankLayout){
            return (
              <LayoutNonAuthorizedLayout>
                <Component {...props} />
              </LayoutNonAuthorizedLayout>
            )
          }
          return(
            <Layout>
              <Component {...props} />
            </Layout>
          )
          
        }
      }}
    />
  );
});

export default Middleware;
