import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import classes from './styles.module.scss';
import QueryString from 'qs';
import * as Yup from 'yup';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { appRoutes } from 'routers/appRoutes';
import { DefaultLogo } from 'assets';
import clsx from 'clsx';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiService from 'services/apiService';
import apiRoutes from 'configs/apiRoutes';
import ToastService from 'services/toastService';
import Messages from 'configs/messages';
import { EStatusCode } from 'configs/enums';
import TokenService from 'services/tokenService';

interface IQueryString {
  email?: string;
}

interface CheckEmailPageProps {}

const CheckEmailPage: React.FC<CheckEmailPageProps> = memo((props: CheckEmailPageProps) => {
  const dispatch = useDispatch();

  const { email }: IQueryString = QueryString.parse(window?.location?.search);

  const [countDown, setCountDown] = useState<number>(0);
  const [resetCountDown, setResetCountDown] = useState<number>(0);

  const token = useMemo(() => TokenService.getToken(), []);

  useEffect(() => {
    if (token) {
      dispatch(push(appRoutes.private.home));
    }
  }, [token]);

  const isShowResend = useMemo(() => {
    return countDown <= 0;
  }, [countDown]);

  useEffect(() => {
    const isValidQuery = Yup.string().email().required().isValidSync(email);
    if (!isValidQuery) {
      dispatch(push(appRoutes.public.login));
    }
  }, [email]);

  useEffect(() => {
    setCountDown(60);
    const interval = setInterval(() => {
      setCountDown((pre) => {
        if (pre < 1) {
          clearInterval(interval);
          return 0;
        }
        return pre - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [resetCountDown]);

  const onResendVerify = async () => {
    if (!isShowResend) return;
    dispatch(setIsLoadingReducer(true));
    await ApiService.POST(apiRoutes.user.sendVerifyEmail, { email })
      .then(() => {
        setResetCountDown(resetCountDown + 1);
        ToastService.success(Messages.success.verifyEmailSent);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === EStatusCode.BadRequest) {
          setResetCountDown(resetCountDown + 1);
        }
        switch (error?.response?.status) {
          case EStatusCode.BadRequest:
            return ToastService.error(Messages.error.waitForSendEmail);
          case EStatusCode.AccessDenied:
          case EStatusCode.NotFound:
            return ToastService.error(Messages.error.emailVerifiedOrNotFound);
          default:
            return ToastService.error(Messages.error.default);
        }
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  return (
    <Fragment>
      <Link to={appRoutes.private.home}>
        <img className={classes.logoImage} src={DefaultLogo} alt="Logo" />
      </Link>

      <p className={classes.title}>Verify your email address</p>

      <p className={classes.content}>
        An email has been sent to <b>{email}</b>. Please open the email and follow the instruction to verify your email address.
      </p>
      <br />
      <p className={classes.content}>
        If you haven't seen the email, please check your spam folder or{' '}
        <span onClick={onResendVerify} className={clsx(classes.resend, { [classes.disabled]: !isShowResend })}>
          resend email
        </span>
        . {!isShowResend ? `(${countDown}s)` : ''}
      </p>
      <Divider className={classes.line} />

      <p>
        Already verified your email address? <Link to={appRoutes.public.login}>Login</Link>
      </p>
    </Fragment>
  );
});

export default CheckEmailPage;
