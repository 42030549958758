import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import TokenService from './tokenService';
import qs from 'qs';
import { EStatusCode } from 'configs/enums';
import { appRoutes } from 'routers/appRoutes';

const getToken = (): string => {
  return 'Bearer ' + TokenService.getToken();
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
api.defaults.headers.common['Authorization'] = getToken();
api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error?.response?.status === EStatusCode.Unauthorized) {
      localStorage.clear();
      window.location.href = appRoutes.public.login;
    }

    return Promise.reject(error);
  }
);

const GET = async (url: string, data: any = {}, config: AxiosRequestConfig = {}): Promise<any> => {
  api.defaults.headers.common['Authorization'] = getToken();
  return await api
    .get(url, {
      ...config,
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      },
    })
    .then((response: AxiosResponse) => {
      return response?.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

const POST = async (url: string, data: any = {}, config: AxiosRequestConfig = {}): Promise<any> => {
  api.defaults.headers.common['Authorization'] = getToken();
  return await api
    .post(url, data, { ...config })
    .then((response: AxiosResponse) => {
      return response?.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

const PATCH = async (url: string, data: any = {}, queryParams: any = {}, config: AxiosRequestConfig = {}): Promise<any> => {
  api.defaults.headers.common['Authorization'] = getToken();

  const urlWithParams = `${url}${Object.keys(queryParams).length > 0 ? '?' : ''}${new URLSearchParams(queryParams)}`;

  return await api
    .patch(urlWithParams, data, { ...config })
    .then((response: AxiosResponse) => {
      return response?.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

const PUT = async (url: string, data: any = {}, config: AxiosRequestConfig = {}): Promise<any> => {
  api.defaults.headers.common['Authorization'] = getToken();
  return api
    .put(url, data, { ...config })
    .then((response: AxiosResponse) => {
      return response?.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

const DELETE = async (url: string, data: any = {}, config: AxiosRequestConfig = {}): Promise<any> => {
  api.defaults.headers.common['Authorization'] = getToken();
  return await api
    .delete(url, {
      ...config,
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      },
    })
    .then((response: AxiosResponse) => {
      return response?.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

const ApiService = {
  GET,
  POST,
  PATCH,
  PUT,
  DELETE,
};

export default ApiService;
