import apiRoutes from 'configs/apiRoutes';
import Messages from 'configs/messages';
import { call, put, takeLatest } from 'redux-saga/effects';
import { setIsLoadingAuthReducer } from 'redux/reducers/Status/actionTypes';
import { GET_ME_REQUEST, setUserReducer } from 'redux/reducers/User/actionTypes';
import ApiService from 'services/apiService';
import ToastService from 'services/toastService';
import TokenService from 'services/tokenService';

function* requestGetMe() {
  try {
    yield put(setIsLoadingAuthReducer(true));
    const token = TokenService.getToken();
    if (!token) {
      yield put(setIsLoadingAuthReducer(false));
      return;
    }
    const response = yield call(ApiService.GET, apiRoutes.user.getMe);
    yield put(setUserReducer(response?.data));
  } catch (error: any) {
    yield console.log(error);
    yield ToastService.error(Messages.error.default);
  } finally {
    yield put(setIsLoadingAuthReducer(false));
  }
}

function* getMe() {
  yield takeLatest(GET_ME_REQUEST, requestGetMe);
}

export default getMe;
