import { memo } from 'react';
import classes from './styles.module.scss';

interface PricingHeaderProps {}

const PricingHeader = memo((props: PricingHeaderProps) => {
  return (
    <div className={classes.container}>
      <p className={classes.title}>Plans and Pricing</p>
      <div>All the plans provide access to all the countries available in our On-Line Demographics Database.</div>
    </div>
  );
});

export default PricingHeader;
