import { memo, useEffect } from 'react';
import classes from './styles.module.scss';
import { Card, CardContent } from '@mui/material';
import { CheckoutSuccessIcon } from 'assets';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { appRoutes } from 'routers/appRoutes';
import { getMeRequest } from 'redux/reducers/User/actionTypes';

interface CheckoutSuccessPageProps {}

const CheckoutSuccessPage: React.FC<CheckoutSuccessPageProps> = memo((props: CheckoutSuccessPageProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(push(appRoutes.private.home));
    }, 5000);

    return () => {
      dispatch(getMeRequest());
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Card sx={{ m: 2, boxShadow: 'var(--commonBoxShadow)' }}>
      <CardContent
        sx={{
          minHeight: 'calc(100vh - 64px - 16px - 16px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          gap: '16px',
        }}
      >
        <CheckoutSuccessIcon />
        <p className={classes.title}>Checkout Success</p>
        <p>Congrats! You can now access to all the countries in our On-Line Demographics Database.</p>
        <p>We are taking you to the homepage...</p>
      </CardContent>
    </Card>
  );
});

export default CheckoutSuccessPage;
