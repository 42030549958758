import CheckEmailPage from 'pages/Authentication/CheckEmail';
import LoginPage from 'pages/Authentication/Login';
import RegisterPage from 'pages/Authentication/Register';
import ForgotPasswordPage from 'pages/Authentication/ForgotPassword';
import { appRoutes } from './appRoutes';
import HomePage from 'pages/Home';
import PricingPage from 'pages/Pricing';
import AccountPage from 'pages/Account';
import SubscriptionPage from 'pages/Subscription';
import VerifyEmailPage from 'pages/Authentication/VerifyEmail';
import ResetPasswordPage from 'pages/Authentication/ResetPassword';
import CheckForgotPasswordEmailPage from 'pages/Authentication/CheckEmailResetPassword';
import AdminImportDatasetPage from 'pages/AdminImportDataset';
import AdminEditTablePage from 'pages/AdminEditTable';
import AdminEditUserPage from 'pages/AdminEditUser';

import CheckoutSuccessPage from 'pages/CheckoutSuccess';
import CheckoutFailedPage from 'pages/CheckoutFailed';
import ConditionPage from 'pages/Condition';

// IMPORTANT: sort by descending route length

export interface IRoute {
  path: string;
  isBlankLayout? : boolean;
  component: React.FC<any>;
}

const publicRoutes: IRoute[] = [
  { path: appRoutes.public.login, component: LoginPage },
  { path: appRoutes.public.register, component: RegisterPage },
];

const privateRoutes: IRoute[] = [
  { path: appRoutes.private.home, component: HomePage },
  { path: appRoutes.private.acceptCondition, component: ConditionPage, isBlankLayout: true },
  { path: appRoutes.private.pricing, component: PricingPage },
  { path: appRoutes.private.account, component: AccountPage },
  { path: appRoutes.private.subscription, component: SubscriptionPage },
  { path: appRoutes.private.checkoutSuccess, component: CheckoutSuccessPage },
  { path: appRoutes.private.checkoutFailed, component: CheckoutFailedPage },
];

const fullyAllowedRoutes: IRoute[] = [
  { path: appRoutes.fullyAllowed.checkEmail, component: CheckEmailPage },
  { path: appRoutes.fullyAllowed.forgotPassword, component: ForgotPasswordPage },
  { path: appRoutes.fullyAllowed.verifyEmail, component: VerifyEmailPage },
  { path: appRoutes.fullyAllowed.resetPassword, component: ResetPasswordPage },
  { path: appRoutes.fullyAllowed.checkForgotPasswordEmail, component: CheckForgotPasswordEmailPage },
];

const adminRoutes: IRoute[] = [
  { path: appRoutes.admin.importDataset, component: AdminImportDatasetPage },
  { path: appRoutes.admin.editTable, component: AdminEditTablePage },
  { path: appRoutes.admin.users, component: AdminEditUserPage },
];

export { publicRoutes, privateRoutes, fullyAllowedRoutes, adminRoutes };
