const exportCSV = (data: string, filename: string) => {
  const csvContent = 'data:text/csv;charset=utf-8,' + data;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const formatNumber = (number: number | null, decimalPlaces: number | null, isPercentage = false) => {
  return number !== null && !Number.isNaN(number)
    ? (isPercentage ? Number(number) * 100 : Number(number))?.toLocaleString('en-US', {
        minimumFractionDigits: decimalPlaces ?? 2,
        maximumFractionDigits: decimalPlaces ?? 2,
      }) + (isPercentage ? '%' : '')
    : 'N/A';
};

const sha256 = async (value: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(value);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashedString = hashArray.map((byte: number) => byte.toString(16).padStart(2, '0')).join('');
  return hashedString;
};

const createArrayFromRange = (n: number, m: number) => {
  const result = [];
  for (let i = n; i <= m; ++i) {
    result?.push(i);
  }
  return result;
};

const getFullName = (user: { firstName?: string; lastName?: string }, emptyValue?: string) => {
  return !user?.firstName && !user?.lastName ? emptyValue ?? 'N/A' : `${user?.firstName} ${user?.lastName}`;
};

const getImageThumbnailUrl = (url: string) => {
  const lastIndex = url?.lastIndexOf('.');
  if (lastIndex !== -1) {
    return url?.slice(0, lastIndex) + '_thumbnail' + url?.slice(lastIndex);
  } else {
    return url;
  }
};

const CommonService = { exportCSV, formatNumber, sha256, createArrayFromRange, getFullName, getImageThumbnailUrl };

export default CommonService;
