import { memo, useState } from 'react';
import classes from './styles.module.scss';
import Sidebar from 'components/Sidebar';
import UserDropdown from 'components/Header/components/UserDropdown';
import { DragIcon } from 'assets';
import { IconButton } from '@mui/material';

interface AdminLayoutProps {
  children: React.ReactNode;
}

const AdminLayout: React.FC<AdminLayoutProps> = memo((props: AdminLayoutProps) => {
  const { children } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classes.container}>
      <Sidebar isOpen={isOpen} handleDrawerToggle={handleDrawerToggle} />
      <div className={classes.main}>
        <div className={classes.header}>
          <IconButton className={classes.toggleButton} color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
            <DragIcon />
          </IconButton>
          <UserDropdown />
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
});

export default AdminLayout;
