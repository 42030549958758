import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import classes from './styles.module.scss';
import Input from 'components/Input';
import * as Yup from 'yup';
import Regexes from 'configs/regexes';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { appRoutes } from 'routers/appRoutes';
import { Link, useParams } from 'react-router-dom';
import { DefaultLogo } from 'assets';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiService from 'services/apiService';
import apiRoutes from 'configs/apiRoutes';
import { EVerifyCodeType } from 'configs/enums';
import ToastService from 'services/toastService';
import Messages from 'configs/messages';

interface Params {
  resetPasswordCode: string;
}

interface IResetPasswordFormData {
  newPassword: string;
  confirmNewPassword: string;
}

interface ResetPasswordPageProps {}

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = memo((props: ResetPasswordPageProps) => {
  const dispatch = useDispatch();

  const { resetPasswordCode } = useParams<Params>();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        newPassword: Yup.string()
          .required('Please enter your new password.')
          .matches(Regexes.password, 'Password must be least 8 characters including an upper case, an lower case, a number, and a special character.'),
        confirmNewPassword: Yup.string()
          .required('Please confirm your new password.')
          .oneOf([Yup.ref('newPassword'), null], 'Confirm new password does not match.'),
      }),
    []
  );

  const [isValidCode, setIsValidCode] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IResetPasswordFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const watchNewPassword = watch('newPassword');
  const watchConfirmNewPassword = watch('confirmNewPassword');

  useEffect(() => {
    if (!resetPasswordCode) {
      dispatch(push(appRoutes.public.login));
      return;
    }

    const checkValidResetPasswordCode = async () => {
      dispatch(setIsLoadingReducer(true));
      await ApiService.POST(apiRoutes.user.checkCode, {
        code: resetPasswordCode,
        type: EVerifyCodeType.ResetPassword,
      })
        .then(() => {
          setIsValidCode(true);
        })
        .catch(() => {
          ToastService.error(Messages.error.resetPasswordInvalidLink);
          dispatch(push(appRoutes.public.login));
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    };

    checkValidResetPasswordCode();
  }, [resetPasswordCode]);

  useEffect(() => {
    if (watchNewPassword !== watchConfirmNewPassword && watchConfirmNewPassword !== '') {
      setError('confirmNewPassword', {
        type: 'match',
        message: 'Confirm new password does not match',
      });
    } else if (watchNewPassword === watchConfirmNewPassword && watchConfirmNewPassword !== '') {
      clearErrors('confirmNewPassword');
    }
  }, [watchConfirmNewPassword, watchNewPassword]);

  const onSubmitPassword = async (data: IResetPasswordFormData) => {
    dispatch(setIsLoadingReducer(true));
    await ApiService.POST(apiRoutes.user.resetPassword, {
      code: resetPasswordCode,
      newPassword: data.newPassword,
    })
      .then(() => {
        ToastService.success(Messages.success.resetPasswordSuccess);
        dispatch(push(appRoutes.public.login));
      })
      .catch((error) => {
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  return (
    <Fragment>
      {isValidCode ? (
        <>
          <Link to={appRoutes.private.home}>
            <img className={classes.logoImage} src={DefaultLogo} alt="Logo" />
          </Link>

          <p className={classes.title}>New Password</p>
          <form onSubmit={handleSubmit(onSubmitPassword)}>
            <Input
              label="New password"
              inputRef={register('newPassword')}
              type="password"
              placeholder="Your new password"
              errorMessage={errors?.newPassword?.message}
            />

            <Input
              className="mb-4"
              label="Confirm new password"
              inputRef={register('confirmNewPassword')}
              type="password"
              placeholder="Confirm your new password"
              errorMessage={errors?.confirmNewPassword?.message}
            />

            <PrimaryButton className="mb-4" type="submit" fullWidth>
              Submit
            </PrimaryButton>
          </form>
        </>
      ) : null}
    </Fragment>
  );
});

export default ResetPasswordPage;
