import { memo, useEffect } from 'react';
import classes from './styles.module.scss';
import { Card, CardContent } from '@mui/material';
import { CheckoutFailedIcon } from 'assets';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { appRoutes } from 'routers/appRoutes';

interface CheckoutFailedPageProps {}

const CheckoutFailedPage: React.FC<CheckoutFailedPageProps> = memo((props: CheckoutFailedPageProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(push(appRoutes.private.home));
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Card sx={{ m: 2, boxShadow: 'var(--commonBoxShadow)' }}>
      <CardContent
        sx={{
          minHeight: 'calc(100vh - 64px - 16px - 16px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          gap: '16px',
        }}
      >
        <CheckoutFailedIcon />
        <p className={classes.title}>Checkout Failed</p>
        <p>Don't worry. You can checkout again anytime.</p>
        <p>We are taking you to the homepage...</p>
      </CardContent>
    </Card>
  );
});

export default CheckoutFailedPage;
