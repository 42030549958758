import { Fragment, memo } from 'react';
import { useSelector } from 'react-redux';
import LoadingScreen from '../LoadingOverlay';
import { IReducer } from 'redux/reducers';

interface AppStatusProps {}

export const AppStatus: React.FC<AppStatusProps> = memo((props: AppStatusProps) => {
  const { isLoading, isLoadingAuth } = useSelector((state: IReducer) => state?.status);

  return <Fragment>{isLoading || isLoadingAuth ? <LoadingScreen /> : null}</Fragment>;
});

export default AppStatus;
